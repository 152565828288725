@use 'styles/variables' as *;
@use 'styles/mixins';

.button {
  @include mixins.standardButton;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  color: var(--button-color);
  &:hover {
    opacity: 0.9;
  }
}

.outlined{
  border: 1px solid var(--button-color);
}

.solid {
  background-color: var(--button-background-color);
}