@use 'styles/mixins';
@use 'styles/variables';

.media {
  width: 100%;
  height: 100%;
  position: relative; // for next/image layout="fill"
}

.flex {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  color: white;
  align-items: center;
  padding: 3rem 2rem;
  justify-content: center;
  overflow: hidden;
  @include mixins.media {
    padding: 6rem 0;
  }
}

.pointerEventsNone {
  pointer-events: none;
}

.section {
  position: relative;
  @include mixins.media {
    height: 60.4rem;
  }
  height: 44rem;
  overflow: hidden;
}

.headline {
  @include mixins.titleTypography;
  color: white;
  font-size: 2.8rem;
  line-height: 100%;
  text-align: center;
  white-space: pre-wrap;
  @include mixins.media {
    font-size: 4.2rem;
    max-width: 80%;
  }
}

.subtitle {
  @include mixins.bodyTypography;
  color: white;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
  line-height: 100%;
  text-align: center;
  width: 95%;
  letter-spacing: -0.1rem;

  @include mixins.media {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
}

.body {
  @include mixins.bodyTypography;
  margin-top: 1.6rem;
  text-align: center;
  color: var(--text-color) !important;
  @include mixins.media {
    margin-top: 2.4rem;
    max-width: 53rem;
  }

  > p {
    color: var(--text-color) !important;
    font-size: 1.4rem;
    line-height: 128%;
    @include mixins.media {
      font-size: 2rem;
      line-height: 120%;
    }
  }
}

.ctaButton {
  pointer-events: all;
  margin-top: 2.4rem;
  @include mixins.media {
    margin-top: 2.8rem;
  }
}

.brandSection {
  position: relative;
  height: 43.6rem;
  // 44.16rem ?
  @include mixins.media {
    position: relative;
    height: 60rem;
  }
  overflow: hidden;
}

.brandHeadline {
  @include mixins.titleTypography;
  color: white;
  width: 33rem;
  height: 8rem;
  font-family: variables.$font-headline;
  font-size: 2.8rem;
  line-height: 110%;
  text-align: center;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  white-space: pre-wrap;
  @include mixins.media {
    width: 71rem;
    font-size: 4.2rem;
    padding: 0;
  }
}

.brandButton {
  margin-top: 1.6rem;

  @include mixins.media {
    margin-top: 2.4rem;
  }
}

.headlineFreight {
  @include mixins.freigthTypography;
  font-size: 3.8rem !important;
  @include mixins.media {
    font-size: 7.2rem !important;
  }
}

.headlineFreightSmall {
  @include mixins.freigthTypography;
  font-size: 3.6rem !important;
  @include mixins.media {
    font-size: 5.2rem !important;
  }
}

.subtitleFreight {
  @include mixins.freigthTypography;
  font-size: 2.6rem !important;
  @include mixins.media {
    font-size: 3.8rem !important;
  }
}

.subtitleFreightSmall {
  @include mixins.freigthTypography;
  font-size: 2rem !important;
  @include mixins.media {
    font-size: 2.8rem !important;
  }
}

.bodyFreight {
  font-family: 'Freight Big' !important;
  letter-spacing: 0;
  * {
    font-family: 'Freight Big' !important;
    letter-spacing: 0 !important;
  }
}
