@use 'styles/mixins';
@use 'styles/variables';

.carouselItem {
  min-width: 100%;
  min-height: 20rem;
  max-height: 60rem;
  scroll-snap-align: start;

  & > div {
    height: 100%;
  }

  &.active {
    transition: transform 0.5s ease-in;
  }
}

.ctaSlide {
  height: 100%;
}

.carouselWrapper {
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: 43.6rem;
  overflow: visible;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  transition: transform .2s ease-in-out;

  @include mixins.media {
    height: 60rem;
  }
}

@mixin arrowButton {
  @include mixins.roundButton;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  width: 3.6rem;
  height: 3.6rem;
  bottom: 2.4rem;
  @include mixins.media {
    bottom: 28.2rem;
  }
}

.buttonWrapper {
  position: absolute;
  width: 100%;
  height: 43.6rem;

  @include mixins.media {
    height: 60rem;
  }
}

.leftArrow {
  @include arrowButton;
  left: 14.5rem;
  @include mixins.media {
    left: 2.8rem;
  }
}

.rightArrow {
  @include arrowButton;
  right: 14.6rem;
  @include mixins.media {
    right: 2.8rem;
  }
}
